@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');

* {
    font-family: 'Lato', sans-serif; color: #333B48; margin: 0; padding: 0; font-size: 14px;
}

body{ overflow: scroll; background-color: #F5F5F7; }

p{ margin-bottom: 0px; }

a { color: #51A5B0; 
    text-decoration: underline;
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
    -o-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;
}

a:hover{ color: rgb(13, 87, 100); }
.margin-top100  {margin-top: 50px;}
.margin-top50  {margin-top: 30px;}
.margin-top20  {margin-top: 20px;}
.margin-top10  {margin-top: 10px;}
.margin-bottom{ margin-bottom: 20px;}

.desktop {display: block;}
.mobile {display: none;}

.tarjetaguias{
    background-color: white;
    border-radius: 15px;
    padding: 15px;
    margin-top: 20px;
}

.tarjetagris{
    background-color: #EFEFF0 ;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
}

.tarjetahistorial{
    margin-bottom: 10px;
    border-radius: 20px;
    padding: 10px
}

.line-height95{ line-height: 95%;}
.line-height160{ line-height: 180%;}
.texto-rojo {color: #FF5959;}
.texto-azul {color: #333B48;}
.texto-gris {color: gray;}
.texto-verde{color: #1A8632; font-size: 16px;}
.fondo-gris{background-color: #E9EBEC; padding: 20px; border-radius: 10px;}
.fondo-blanco{ background-color: white; padding: 5px; border-radius: 20px;}
.fondo-blanco-productos{ background-color: white; padding: 0px; border-radius: 20px;}
.font-bold {font-weight: 700;}
.font-light {font-weight: normal;}
.font-size12{font-size: 12px;}
.padding{ padding:20px;}
.padding-10{ padding:10px;}
.padding-left-10{ padding: 0px 0px 0px 10px;}
.no-padding{ padding: 0%;}
.padding-t-b{padding: 10px 0px;}
.padding-bottom{ padding-bottom:  20px;}
.border-bottom10{border-bottom: rgb(221, 220, 220) solid 2px;}
.diablito{width:25%;}
.logos-header{width: 50%;}
.courier-logo {
    height: 60px;
    padding-top: 20px;
    width: auto;
}
.courier-logo.STF {padding-top: 30px;}
.bordes{ background-image: url(../img/Lineas.png);
    background-repeat: no-repeat;
    background-position: center; 
    background-size: contain; }
.shadow-10{box-shadow: 0px 0px 10px rgba(196,196,196,0.3);}
.footer{ background-color: #2E3A48; padding: 50px;}
.footer p{ color: white;}
.footer a{ color: white;}
.footer a:hover{ color: #51A5B0;
}

table thead th { font-size: 12px; text-align: center; color: #FF5959;}
table tbody, table tbody th { text-align: center; font-weight: 900;}
.table-borderless tbody+tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
    border: 0;
    vertical-align: baseline;
}

.table td, .table th {
    padding: .35rem;
    vertical-align: top;
    border-top: 0px solid #dee2e6;
}


.texto-collapse {color: #51A5B0;  font-size: 16px; text-decoration: underline;}
.texto-collapse:hover{color: rgb(13, 87, 100);
    text-decoration: underline;}

.btn-link:hover {
      color: rgb(13, 87, 100);
      text-decoration: underline;
}

.espacio{
    display: inline-block;
    width: 5px;
}

.text-responsive {text-align: right}
.accordion , .card , .card-header , .collapse  {
    border: 0px;
    background-color: #EFEFF0; 
}

.step {
    height: 92px;
}
.step .border-step {
    background: #41c247;
    height: 100%;
    width: 2px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.step .border-step.border-step-first {
    top: 58%;
}
.step .border-step.border-step-last {
    bottom: 50%;
    height: 40%;
}

.step .border-step-gray {
    background: #d5dadf;
}
.step .border-step.border-step-transparent {
    background: transparent;
    height: 42px;
    width: 2px;
    margin: 0 auto;
}
.step img {
    width: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    margin: 0 auto;
    position: absolute;
    top: 26%;
    left: 0;
    right: 0;
}

.card-body {padding: 0;}

.loading-message {
    margin-top: 10%;
    text-align: center;
}
.loading-message p {
    font-size: 40px;
}

.error-message {
    margin-top: 10%;
    text-align: center;
}
.error-message p {
    font-size: 40px;
}
.error-message p.error-message-footer {
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
}
.error-message p.error-message-footer span {
    display: block;
    font-size: 14px;
    font-weight: normal;
}

@media only screen and (min-width : 200px) {
.margin-productos{ margin-top: 10px;}
.fondo-blanco img {width: 90%; border-radius: 20px; padding: 1%;}
.titulo-tarjeta {font-size: 24px;}
.padding-tarjetas{padding: 0px 20px;}
.text-responsive-right {text-align: center;}
.text-responsive-left {text-align: center;}
.margin-movil-terminos {margin-top: 20px;}
.container-scroll {height: auto; overflow: hidden;}
.padding-tarjeta-movil-left{ padding-left: 10px; padding-right: 0px;}
.padding-tarjeta-movil-right{ padding-left: 0px; padding-right: 10px;}
.height-card {height: 300px;}
.img-height{ height: 110px;}
.margin-status-movil {margin-bottom: 50px;}
.historial {height: auto; overflow: hidden;}
.texto-productos{height: 80px;}
.tarjetablanca{
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;}
}

@media only screen and (max-width : 390px) {
    .desktop {display: none;}
    .mobile {display: block;}
    
    .courier-logo {
        max-height: 80px;
        padding-top: 0px;
        width: auto;
    }
    .courier-logo.STF {
        max-height: 40px;
        padding-top: 20px;
        width: auto;
    }
    .courier-logo.FDX {
        max-height: 50px;
        padding-top: 10px;
        width: auto;
    }
    
    .fondo-general-movil {background-color: #E9EDF2; padding: 15px;;}
    .mi-pedido {border-bottom: #d5d6df solid 1px; margin-bottom: 10px; padding-bottom: 10px;}
    .guia-movil {
        padding: 30px 10px 15px; 
        margin-bottom: 20px; 
        background-image: url(../img/fondo-header-movil.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .guia-movil b {font-size: 1.2em; font-weight: 900; letter-spacing: -1px;}
    .estatus-movil {
        padding: 15px; 
        border-radius: 10px;
        border: #d5d6df solid 1px;
        margin-bottom: 20px;
        color: #333B48;
        font-size: 0.8em;
    }
    .estatus-movil b {font-size: 1.5em; font-weight: 900; letter-spacing: -1px; color: #333B48;}
    .estatus-movil a {font-size: 1.5em; font-weight: 700; color: #333B48;}
    .destinatario-movil {
        padding: 15px; 
        border-radius: 10px;
        background: #ffffff;
        margin-bottom: 20px;
        color: #000000;
        -webkit-box-shadow: 2px 2px 19px -3px rgba(0,0,0,0.26);
        -moz-box-shadow: 2px 2px 19px -3px rgba(0,0,0,0.26);
        box-shadow: 2px 2px 19px -3px rgba(0,0,0,0.26);
        font-size: 0.8em;
    }
    .border-destinatario-movil {border-bottom: #E9EDF2 solid 1px; padding-bottom: 10px; margin-bottom: 10px;}
    .border-destinatario-movil b {font-size: 1.0em; font-weight: 900; letter-spacing: -1px;}
    .border-destinatario-movil i {font-size: 3em; color: #E9EDF2;}
    .subtitulo-destino-movil {font-size: 0.5em; font-weight: 900; text-transform: uppercase; color: #5566F6;}

    .tarjetagris{
        background-color: transparent ;
        border-radius: 0;
        padding: 0;
        margin-bottom: 0;
    }

    .tarjetahistorial{
        border-radius: 0;
        padding: 0
    }
    .fondo-blanco{
        background-color: transparent;
        border-radius: 0;
        padding: 5px;
    }
    .shadow-10{box-shadow: initial;}
    .panel-body {margin: 10px 0 30px;}
}

@media only screen and (min-width : 390px) {
    .fondo-blanco img {width: 55%;}
    .img-height{ height: 130px;}
}

@media only screen and (min-width : 576px) {
    .margin-status-movil {margin-bottom: 50px;}
    .fondo-blanco img {width: 60%;}
    .margin-movil-terminos {margin-top: 0px;}
    .texto-productos{height: 80px;}
    .tarjetablanca{
        background-color: white;
        border-radius: 20px;
        padding: 30px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width : 768px) {
.fondo-blanco img {width: 50%;}
.img-height{ height: 150px;}
.titulo-tarjeta {font-size: 24px;}
.text-responsive-right {text-align: right;}
.text-responsive-left {text-align: left;}
.margin-top50  {margin-top: 50px;}
.margin-top100  {margin-top: 100px;}
.padding-t-b{padding: 50px 0px;}
.diablito{width:15%;}
.texto-collapse {font-size:14px;}
.btn {padding: .375rem 3rem;}

}

@media only screen and (min-width : 992px) {
.fondo-blanco img {width: 100%; padding: 5%;}
.titulo-tarjeta {font-size: 26px;}
.padding-tarjeta-movil-left{ padding-left: 0px; padding-right: 0px;}
.padding-tarjeta-movil-right{ padding-left: 0px; padding-right: 0px;}
.margin-productos{ margin-top: 10px;}
.container-scroll {height: 350px; overflow: scroll;}
.height-card {height: auto;}
.height-img {height: auto;}
.img-height{ height: 100px;}
.margin-status-movil {margin-bottom: 0px;}
.historial { max-height: 750px; overflow: scroll;}
.texto-productos{height: auto;}
}


@media only screen and (min-width : 1200px) {
.img-height{ height: 130px;}

}
